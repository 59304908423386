import { useQuery } from 'react-query';

import axios from '../axios';
import { IUser } from '../types';

const fetchUser = async (): Promise<IUser> => {
  const isLoop = process.env.REACT_APP_IS_LOOP === 'true';
  const url = isLoop ? '/gateway/tilly/loop/me' : '/user/me';
  const response = await axios.get<unknown, IUser>(url);

  if (!isLoop) {
    return {
      ...response,
      teamName: 'https://slack.com/team_name',
      teamId: 'https://slack.com/team_id',
    };
  }
  return response;
};

const useGetMe = () =>
  useQuery<IUser, Error>('user', fetchUser, {
    staleTime: Infinity,
    retry: false,
  });

export default useGetMe;
