import { useMutation } from 'react-query';
import { useSearchParams } from 'react-router-dom';

import axios from '../axios';

import queryClient from './queryClient';

const notDeletableQueries = ['workspaceId', 'workspaceName'];

const useLogoutMutation = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isLoop = process.env.REACT_APP_IS_LOOP === 'true';

  const clearSearchParamsAndCache = () => {
    const keys = Array.from(searchParams.keys());
    keys.forEach((key) => {
      if (!notDeletableQueries.includes(key)) {
        searchParams.delete(key);
      }
    });

    setSearchParams(searchParams);
    queryClient.resetQueries('user');
  };

  return useMutation(
    () => {
      // If the application is running in Loop mode, simply clear the local storage
      // because the received token cannot be revoked with Google authentication
      if (isLoop) {
        localStorage.removeItem('token');
        localStorage.removeItem('selectAll');
        clearSearchParamsAndCache();
        return Promise.resolve();
      }

      return axios.post('/user/logout').then(() => {});
    },
    {
      onSuccess: () => {
        if (!isLoop) {
          clearSearchParamsAndCache();
        }
      },
    },
  );
};

export default useLogoutMutation;
