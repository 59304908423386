import { useMutation } from 'react-query';

import axios from '../axios';
import { Workspace } from '../types/workspace';

const ensureWorkspaceQuery = async (token: string) => {
  const { data } = await axios.get('/gateway/tilly/loop/workspace', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

const useEnsureWorkspaceMutation = () =>
  useMutation<Workspace, Error, string, unknown>(async (token: string) => {
    const response = await ensureWorkspaceQuery(token);
    return response;
  });

export default useEnsureWorkspaceMutation;
