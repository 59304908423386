import React from 'react';
import { StringParam, useQueryParams } from 'use-query-params';

import { Plan } from '../../statistics/types/enums';
import useGetWorkspaceInfo from '../../statistics/queries/useGetWorkspaceInfo';
import LoadingPage from '../loading';
import useGetMe from '../../queries/useGetMe';
import ReLoginErrorPage from '../error/concrete/ReLoginErrorPage';

import UpgradePageUpgraded from './upgraded';
import UpgradePageRedirect from './redirect';

export default function UpgradePage(): JSX.Element {
  const { data: workspace, isLoading: workspaceInfoIsLoading } = useGetWorkspaceInfo();
  const { data: user, isLoading: meIsLoading } = useGetMe();
  const [{ workspaceId: fromWorkspaceId, workspaceName: fromWorkspaceName }] = useQueryParams({
    workspaceId: StringParam,
    workspaceName: StringParam,
  });

  if (workspaceInfoIsLoading || meIsLoading) {
    return <LoadingPage />;
  }

  const currentWorkspaceId = user?.teamId;
  const currentWorkspaceName = user?.teamName;

  if (fromWorkspaceId && currentWorkspaceId !== fromWorkspaceId) {
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    return (
      <ReLoginErrorPage
        currentWorkspaceName={currentWorkspaceName!}
        fromWorkspaceName={fromWorkspaceName ?? 'Unknown'}
      />
    );
  }

  if (workspace?.plan === Plan.Pro) {
    return <UpgradePageUpgraded />;
  }

  if (workspace?.plan === Plan.Trial || workspace?.plan === Plan.Free) {
    return <UpgradePageRedirect />;
  }

  throw new Error(`Plan has none of the presented values (plan is ${workspace?.plan})`);
}
