import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Flex, Text } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { GoogleOAuthProvider, CredentialResponse, GoogleLogin } from '@react-oauth/google';

import { ReactComponent as TealLogo } from '../../images/google-logo.svg';
import useEnsureWorkspaceMutation from '../../queries/useEnsureWorkspaceMutation';

import useStyles from './GoogleSignIn.style';

// eslint-disable-next-line no-console
const handleError = () => console.log('Google login failed');

function GoogleSignIn() {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { mutateAsync } = useEnsureWorkspaceMutation();

  const onSuccess = useCallback(
    async (credentialResponse: CredentialResponse) => {
      const { credential } = credentialResponse;
      if (credential) {
        localStorage.setItem('token', credential);
        await mutateAsync(credential);

        localStorage.setItem('selectAll', 'true');
        navigate('/settings');
      }
    },
    [mutateAsync, navigate],
  );

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID || ''}>
      <Flex className={classes.wrapper}>
        <TealLogo />
        <Text className={classes.message} align="center">
          {t('GoogleSignInMessage')}
        </Text>
        <GoogleLogin onSuccess={onSuccess} onError={handleError} />
      </Flex>
    </GoogleOAuthProvider>
  );
}

export default GoogleSignIn;
